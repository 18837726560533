
  import { defineComponent, ref } from "vue"
  import { SingleResourceDoc } from '@/models/jsonapi'

  interface Props {}

  export default defineComponent({
    props: {},
    setup(props: Props) {
      return {

      }
    }
  })
